var Mobile;

(function($) {
    Mobile = function() {
        var self = this;

        self.init = function() {
            initMMenu();
            closeMMenuOnResize();
        };
    };

    initMMenu = function () {
        if ($("#mobile-navigation").length > 0) {
            $("#mobile-navigation").mmenu({
                "navbars": [{
                    "position": "top",
                    "content": [
                        $('#language-switcher-mobile')
                    ],
                    "height": 1
                }]
            },{
                offCanvas: {
                    position: "left"
                }
            }, {
                // configuration
                classNames: {
                    selected: 'current'
                }
            });
        }
    };

    closeMMenuOnResize = function () {
        var mmenu = $("#mobile-navigation").data("mmenu");
        $(window).resize(function () {
            mmenu.close();
        });
    };

    var mobile = new Mobile();

    $(document).ready(function() {
        mobile.init();
    });
})(jQuery);